import { Machine, assign } from 'xstate'

const hearts = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'h8',
  'h9',
  'h10',
  'h11',
  'h12',
  'h13'
]
const spades = [
  's1',
  's2',
  's3',
  's4',
  's5',
  's6',
  's7',
  's8',
  's9',
  's10',
  's11',
  's12',
  's13'
]
const diamonds = [
  'd1',
  'd2',
  'd3',
  'd4',
  'd5',
  'd6',
  'd7',
  'd8',
  'd9',
  'd10',
  'd11',
  'd12',
  'd13'
]
const clubs = [
  'c1',
  'c2',
  'c3',
  'c4',
  'c5',
  'c6',
  'c7',
  'c8',
  'c9',
  'c10',
  'c11',
  'c12',
  'c13'
]

function shuffle (a) {
  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

const initialState = {
  cards: [],
  discardPile: [],
  guess: '',
  difficulty: '',
  stacks: []
}

const gameMachine = Machine(
  {
    id: 'memorizeCards',
    initial: 'selectGameType',
    context: initialState,
    states: {
      selectGameType: {
        on: {
          LVL_ONE: {
            target: 'playGame',
            actions: assign({
              difficulty: 'LVL_ONE',
              stacks: ['hearts'],
              cards: () => shuffle(hearts)
            })
          },
          LVL_TWO: {
            target: 'playGame',
            actions: assign({
              difficulty: 'LVL_TWO',
              stacks: ['hearts', 'spades'],
              cards: () => shuffle([...hearts, ...spades])
            })
          },
          LVL_THREE: {
            target: 'playGame',
            actions: assign({
              difficulty: 'LVL_THREE',
              stacks: ['hearts', 'spades', 'diamonds'],
              cards: () => shuffle([...hearts, ...spades, ...diamonds])
            })
          },
          LVL_FOUR: {
            target: 'playGame',
            actions: assign({
              difficulty: 'LVL_FOUR',
              stacks: ['hearts', 'spades', 'diamonds', 'clubs'],
              cards: () =>
                shuffle([...hearts, ...spades, ...diamonds, ...clubs])
            })
          }
        }
      },
      playGame: {
        // TODO: on entry start timer
        on: {
          NEXT_CARD: {
            actions: assign({
              discardPile: ctx => [ctx.cards[0], ...ctx.discardPile],
              cards: ctx => ctx.cards.slice(1)
            }),
            cond: ctx => {
              return ctx.cards.length > 1
            }
          },
          GUESS_CARD: {
            actions: assign({ guess: (_ctx, event) => event.card }),
            cond: ctx => ctx.cards.length === 1
          },
          '': [
            {
              target: 'win',
              cond: ctx => ctx.guess && ctx.guess === ctx.cards[0]
            },
            {
              target: 'lose',
              cond: ctx => ctx.guess && ctx.guess !== ctx.cards[0]
            }
          ]
        }
      },
      win: {
        on: {
          RESET: {
            target: 'selectGameType',
            actions: 'reset'
          }
        }
      },
      lose: {
        on: {
          RESET: {
            target: 'selectGameType',
            actions: 'reset'
          }
        }
      }
    }
  },
  {
    actions: {
      reset: assign(initialState)
    }
  }
)

export default gameMachine
