import React from 'react'
import { useMachine } from '@xstate/react'
import gameMachine from '../stateMachines/gameMachine'
import { useTransition } from 'react-spring'
import Card from '../components/Card'
import styles from './index.module.scss'

export default () => {
  const [current, send] = useMachine(gameMachine)
  const discardPileTransitions = useTransition(
    current.context.discardPile,
    item => item,
    {
      from: { transform: 'rotateY(-180deg)', left: '0px' },
      enter: { transform: 'rotateY(0deg)', left: '250px' },
      leave: { transform: 'rotateY(0deg)', left: '250px' }
    }
  )

  if (current.matches('selectGameType')) {
    return (
      <div>
        <h1>Select Difficulty</h1>
        <button onClick={() => send('LVL_ONE')}>One</button>
        <button onClick={() => send('LVL_TWO')}>Two</button>
        <button onClick={() => send('LVL_THREE')}>Three</button>
        <button onClick={() => send('LVL_FOUR')}>Four</button>
      </div>
    )
  }

  if (current.matches('playGame')) {
    return (
      <div>
        <h1>PLAY</h1>
        <p>
          stacks in play:{' '}
          {current.context.stacks && current.context.stacks.join(', ')}
        </p>
        <p>Drawn card: {current.context.discardPile[0]}</p>

        <div className={styles.cardContainer}>
          {current.context.cards.length !== 1 && (
            <Card flipped onClick={() => send('NEXT_CARD')} />
          )}
          {discardPileTransitions.map(({ item, props, key }) => (
            <Card
              key={key}
              type={item}
              aStyle={{transform: props.transform}}
              containerAStyle={{ position: 'absolute', top: 0, left: props.left, zIndex: key }}
            />
          ))}
        </div>
        <input
          type='text'
          placeholder='guess'
          disabled={current.context.cards.length > 1}
          onBlur={e => {
            send({
              type: 'GUESS_CARD',
              card: e.target.value
            })
          }}
        />
      </div>
    )
  }
  if (current.matches('win')) {
    return (
      <div>
        <h1>WINNING!</h1>
        <button onClick={() => send('RESET')}>reset</button>
      </div>
    )
  }
  if (current.matches('lose')) {
    return (
      <div>
        <h1>LOSING! ({current.context.cards[0]})</h1>
        <button onClick={() => send('RESET')}>reset</button>
        <Card type={current.context.cards[0]} />
      </div>
    )
  }
}
