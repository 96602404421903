import React from 'react'
import { useSpring, animated as a } from 'react-spring'
import styles from './Card.module.scss'
import cards from './cardImages'

const Card = ({ type = 'joker', flipped = false, onClick, aStyle, containerAStyle }) => {
  const { transform } = useSpring({
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  return (
    <a.div className={styles.container} onClick={onClick} style={containerAStyle}>
      <a.div className={styles.card} style={aStyle ? aStyle : {transform}}>
        <div className={styles.cardFront}>
          <img src={cards[type]} />
        </div>
        <div className={styles.cardBack}>
        </div>
      </a.div>
    </a.div>
  )
}

export default Card