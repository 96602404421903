import h1 from '../../images/cards/h1.svg'
import h2 from '../../images/cards/h2.svg'
import h3 from '../../images/cards/h3.svg'
import h4 from '../../images/cards/h4.svg'
import h5 from '../../images/cards/h5.svg'
import h6 from '../../images/cards/h6.svg'
import h7 from '../../images/cards/h7.svg'
import h8 from '../../images/cards/h8.svg'
import h9 from '../../images/cards/h9.svg'
import h10 from '../../images/cards/h10.svg'
import h11 from '../../images/cards/h11.svg'
import h12 from '../../images/cards/h12.svg'
import h13 from '../../images/cards/h13.svg'
import d1 from '../../images/cards/d1.svg'
import d2 from '../../images/cards/d2.svg'
import d3 from '../../images/cards/d3.svg'
import d4 from '../../images/cards/d4.svg'
import d5 from '../../images/cards/d5.svg'
import d6 from '../../images/cards/d6.svg'
import d7 from '../../images/cards/d7.svg'
import d8 from '../../images/cards/d8.svg'
import d9 from '../../images/cards/d9.svg'
import d10 from '../../images/cards/d10.svg'
import d11 from '../../images/cards/d11.svg'
import d12 from '../../images/cards/d12.svg'
import d13 from '../../images/cards/d13.svg'
import c1 from '../../images/cards/c1.svg'
import c2 from '../../images/cards/c2.svg'
import c3 from '../../images/cards/c3.svg'
import c4 from '../../images/cards/c4.svg'
import c5 from '../../images/cards/c5.svg'
import c6 from '../../images/cards/c6.svg'
import c7 from '../../images/cards/c7.svg'
import c8 from '../../images/cards/c8.svg'
import c9 from '../../images/cards/c9.svg'
import c10 from '../../images/cards/c10.svg'
import c11 from '../../images/cards/c11.svg'
import c12 from '../../images/cards/c12.svg'
import c13 from '../../images/cards/c13.svg'
import s1 from '../../images/cards/s1.svg'
import s2 from '../../images/cards/s2.svg'
import s3 from '../../images/cards/s3.svg'
import s4 from '../../images/cards/s4.svg'
import s5 from '../../images/cards/s5.svg'
import s6 from '../../images/cards/s6.svg'
import s7 from '../../images/cards/s7.svg'
import s8 from '../../images/cards/s8.svg'
import s9 from '../../images/cards/s9.svg'
import s10 from '../../images/cards/s10.svg'
import s11 from '../../images/cards/s11.svg'
import s12 from '../../images/cards/s12.svg'
import s13 from '../../images/cards/s13.svg'
import joker from '../../images/cards/joker.svg'

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  h9,
  h10,
  h11,
  h12,
  h13,
  d1,
  d2,
  d3,
  d4,
  d5,
  d6,
  d7,
  d8,
  d9,
  d10,
  d11,
  d12,
  d13,
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  c11,
  c12,
  c13,
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
  s7,
  s8,
  s9,
  s10,
  s11,
  s12,
  s13,
  joker
}
